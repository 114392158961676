import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './Guards/auth.guard';
import { FirebaseService } from './Services/Firebase/firebase.service';
import { RedirectComponent } from './redirect/redirect.component';
import { PayComponent } from './pay/pay.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    resolve:{
      roleResolve: FirebaseService
    },
    canActivate:[AuthGuard]
  },

  { 
    path: 'redirect', 
    component: RedirectComponent,
      resolve:{
        roleResolve: FirebaseService
      },
    canActivate:[AuthGuard]
  },

  {
    path: 'login',
    component:LoginComponent
  },
  {
    path: 'pay/:id',
    component:PayComponent,
    resolve:{
      roleResolve: FirebaseService
    }
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
