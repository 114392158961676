import { guid } from "@datorama/akita";

export interface Song {
    id: number | string;
    storyName: string;
    storyTitle: string;
    storyAuthor: any;
    storyUrl: string;
    storyDuration:any;
    storyCoverImg: string;
    currentTime?: number;
  }
  
  export function createSong(params: Partial<Song>) {
    return {
      id: guid(),
      storyName: params.storyName,
      storyTitle: params.storyTitle,
      storyUrl: params.storyUrl,
      storyAuthor: params.storyAuthor,
      storyDuration: params.storyDuration,
      storyCoverImg: params.storyCoverImg,
      currentTime: params.currentTime
    } as Song;
  }