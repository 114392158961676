import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { MobilepayComponent } from '../mobilepay/mobilepay.component';
import { FirebaseService } from '../Services/Firebase/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MainService } from '../Services/Main/main.service';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit {


  email:any;
  phone:any;
  role:any;
  paid:any

  constructor(
    public modalController: ModalController,
    private firebaseService:FirebaseService,
    private router: Router,
    private toastController: ToastController,
    private auth:AngularFireAuth,
    private mainService:MainService,
    private activeRoute:ActivatedRoute
  ) {
    this.email = this.activeRoute.snapshot.paramMap.get("id")

    this.firebaseService.getIfUserPaidRealTime(this.email).subscribe((data:any)=>{
      this.paid = data[0].payload.doc.data()["paid"]
      if(this.paid === true){
        this.router.navigate(['/'])
      }
    })
  }

  ngOnInit() {}

  async paywithMobile(){
    const modal = await this.modalController.create({
      component: MobilepayComponent,
      cssClass: 'my-custom-class',
    });
    return await modal.present();
  }

  paywithBank(){
    // this.matSnackbar.open("Please wait, you will be redirected...","",{duration:10000})
    this.presentToast("bottom","Please wait, you will be redirected...")
    this.mainService.getPaymentUrl(
      "VeronicaSimulizi",
      "USD",
      this.email,
      this.email
    ).subscribe((data)=>{
      // console.log(data);
    })

    setTimeout(()=>{
      // code to be executed after 10 seconds
      this.checkPesaPalStatus(this.email)
    }, 8000);
  }

  checkPesaPalStatus(phone:any){
   
    this.firebaseService.getPesaPalRedirect(phone).subscribe((data:any)=>{
     
      if(data !== undefined){
        window.location.href = data[0].payload.doc.data()["url"];
        //delete the id
        this.firebaseService.deleteRedirectUrl(data[0].payload.doc.id)
      }
      
     
    })
  }

  async presentToast(position: 'top' | 'middle' | 'bottom',message:any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 6000,
      position: position
    });

    await toast.present();
  }
}
