import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseService } from '../Services/Firebase/firebase.service';
import { MainService } from '../Services/Main/main.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email:any
  loading:any
  constructor(
    private router:Router,
    private mainService:MainService,
    private firebaseService:FirebaseService,
    private authFirebase:AngularFireAuth
  ) {

    this.loading = true

    this.authFirebase.authState.subscribe((data)=>{
      if(data !== null){
        // this.hide=true
        this.email = data.email
        this.mainService.globalUserEmail = data.email
         //Add user data
         this.firebaseService.checkUser(this.email).subscribe((data)=>{
          
          if(data.exists === false){
            this.loading = false
            this.firebaseService.addUserData(this.email).then(()=>{
              this.router.navigate(['/redirect'])
              localStorage.clear()
            })
          }else{
            this.router.navigate(['/redirect'])
          }

         })

      }else{
        this.loading=false
      }
    })


  }

  ngOnInit() {}

  goGoogle(){
    // console.log("CLICK")
    this.authFirebase
    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then((results)=>{
      this.router.navigate(['/redirect'])
    })
  }

  goYahoo(){
    this.authFirebase
    .signInWithRedirect(new firebase.auth.OAuthProvider('yahoo.com'))
    .then(()=>{
      this.router.navigate(['/redirect'])
    })
  }

}
