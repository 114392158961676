import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseService } from './Services/Firebase/firebase.service';
const { StatusBar: StatusBarPlugin } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  hide:any
  email:any
  role:any
  paid:any
  
  constructor(
    private platform: Platform,
    private authFirebase:AngularFireAuth,
    private router:Router,
    private firebaseService:FirebaseService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    
    this.initializeApp();

    this.authFirebase.authState.subscribe((data:any)=>{
      if(data !== null){
        this.hide=false
        this.email = data.email
        this.firebaseService.getUserPermission(this.email).subscribe((data:any)=>{
          this.role = data.data()['role']
          this.paid = data.data()['paid']
        })
        // console.log("Email is present");
        // this.router.navigate(['/'])
      }else{
        this.hide = true
        // console.log('No account');
        
        this.router.navigate(['/redirect'])
      }
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // Set statusbar text color
      // iOS only
      StatusBarPlugin.setStyle({
        style: StatusBarStyle.Dark
      });

      // Android only
      StatusBarPlugin.setBackgroundColor({
        color: '#0f0f0f'
      })
    });
  }
}
