import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LoginComponent } from './login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RedirectComponent } from './redirect/redirect.component';
import { PayComponent } from './pay/pay.component';
import { MobilepayComponent } from './mobilepay/mobilepay.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CategoryviewComponent } from './categoryview/categoryview.component';


@NgModule({
  declarations: [
    AppComponent,
    PayComponent,
    MobilepayComponent,
    RedirectComponent,
    CategoryviewComponent,
    LoginComponent],

  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({ mode: 'ios', backButtonText: '' }),
    AppRoutingModule,
    SuperTabsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,

    FlexLayoutModule
    // AngularFireDatabaseModule,
    // AngularFireStorageModule,
    // AngularFireAnalyticsModule,
    // AngularFirestoreModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
