import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../Services/Firebase/firebase.service';

@Component({
  selector: 'app-categoryview',
  templateUrl: './categoryview.component.html',
  styleUrls: ['./categoryview.component.scss'],
})
export class CategoryviewComponent implements OnInit {

  categoryName:any
  allData:any

  loading = false
  albumArray:any

  constructor(
    private activetedRoute:ActivatedRoute,
    private router:Router,
    private firebaseService:FirebaseService
  ) {
    
    this.loadAllStories()
  }

  ngOnInit() {}

  doRefresh(event:any){
    this.loadAllStories()
    setTimeout(() => {
      // console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  loadAllStories(){
    this.loading = true

    this.categoryName = this.activetedRoute.snapshot.paramMap.get("id")
    this.firebaseService.getAlbumByCategory(this.categoryName)
    .subscribe((data)=>{
      this.albumArray = data
      this.loading = false
    })
  }

  openAlbum(album:any){
    this.router.navigate(['home','story',album])
  }

}
