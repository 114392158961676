import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MainService } from '../Services/Main/main.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authFirebase: AngularFireAuth,
    private mainService:MainService,
    private router:Router) {
      
    }
    
  async canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot):Promise<boolean> {
    const user = await this.authFirebase.currentUser;
    if(user?.uid){
      this.mainService.globalUserEmail = user.email
      return true
    }else{
      this.router.navigate(["/login"])
      return false
    }
}
  
}
