import { Injectable } from '@angular/core';
import { Song } from '../Audio/Models/song.model';
import { SongsStore } from './stores/song.store';
import { ID } from '@datorama/akita';

@Injectable({
  providedIn: 'root'
})
export class SongStoreService {

  constructor(
    private songsStore:SongsStore
  ) { }

  addSongs(songsData:Song[]){
    this.songsStore.set(songsData)
  }

  update(id, song: Partial<Song>) {
    this.songsStore.update(id, song);
  }

  remove(id: ID) {    
    this.songsStore.remove(id);
  }

  

  removeAllSongs(){
    this.songsStore.remove();
  }
}
