import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MainService } from '../Main/main.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  usersCollection="Users"
  requestToPayCollection = "PayRequest"
  pesaPalRedirectCollectionName = "PesaPalRedirects"


  constructor(
    private db: AngularFirestore,
    private mainService:MainService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any> {
      
    if(this.mainService.globalUserEmail !== undefined && 
      this.mainService.globalUserEmail !== null){
      return this.getUserPermission(this.mainService.globalUserEmail)    
    }else{
      var email
      var emailEncryted = localStorage.getItem("email")
      if(emailEncryted){
        email = this.mainService.decryptText(emailEncryted)
      }
     
      return this.getUserPermission(email)    
    }
  }

  getUserPermission(email:any){
    return this.db.collection(this.usersCollection).doc(email).get()
  }

  checkUser(email:any){
    return this.db.collection("Users").doc(email).get()
  }

  async addUserData(email:any) {
    return this.db.collection('Users').doc(email).set({
      email,
      paid:false,
      role:"no",
      date: new Date()
    }).catch((error:any) => {
      console.log(error);
    });
  }

  async addToLike(
    email:any,
    name:any
  ){
    this.db.collection("MyLikes")
    .doc(name+"_"+email)
    .set({
      id:name+"_"+email,
      email,
      name,
      date: new Date()
    })
  }

  async addRequestToPay(
    email:any,
    phone:any,
    business:any
  ){
    this.db.collection(this.requestToPayCollection)
    .add({
      email,
      phone,
      business,
      date: new Date()
    })
  }

  getIfUserPaidRealTime(email:any){
    return this.db.collection(this.usersCollection,
      ref=>ref.where("email","==",email).limit(1)).snapshotChanges()
  }

  getAllCategories(){
    return this.db.collection("Albums",(ref:any)=>ref.orderBy("date","desc")).snapshotChanges()
  }

  getAlbumByCategory(category:any){
    return this.db.collection("Albums",
    (ref)=>ref
    .where("categories","array-contains",category)
    .orderBy("date","desc"))
    .snapshotChanges()
  }

  getPesaPalRedirect(phone:any){
    return this.db.collection(
      this.pesaPalRedirectCollectionName,
      (ref:any)=>ref.where("phone","==",phone).limit(1)).snapshotChanges()
  }

  deleteRedirectUrl(docId:any){
    this.db.collection(
      this.pesaPalRedirectCollectionName).doc(docId).delete()
  }

  getAllStories(name:any){
    return this.db.collection("Songs",(ref:any)=>ref.where("genre","==",name).orderBy("date","asc")).snapshotChanges()
  }

  getIfAlreadyLiked(email:any,name:any){
    return this.db.collection("MyLikes").doc(name+"_"+email).get()
  }

  getAllLikedStories(email:any){
    return this.db.collection("MyLikes",ref=>ref.where("email","==",email)).snapshotChanges()
  }

  listOfAllCategories(){
    return this.db.collection("Categories",(ref:any)=>ref.orderBy("date","asc")).snapshotChanges()
  }
}
