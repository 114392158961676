import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FirebaseService } from '../Services/Firebase/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-mobilepay',
  templateUrl: './mobilepay.component.html',
  styleUrls: ['./mobilepay.component.scss'],
})
export class MobilepayComponent implements OnInit {

  email:any
  phone:any
  constructor(
    public modalController: ModalController,
    private firebaseService:FirebaseService,
    private toastController: ToastController,
    private authFirebase:AngularFireAuth,
  ) {

    this.authFirebase.authState.subscribe((data:any)=>{
      this.email = data.email
    })
  }

  ngOnInit() {}

  lipa(){
    this.firebaseService.addRequestToPay(this.email,this.phone,"VeronicaSimulizi").then(()=>{
      this.phone=""
      this.presentToast("bottom","Subiri kidogo uingize pin yako ya malipo, Ukimaliza malipo refresh page yako")
      
    })
  }

  async presentToast(position: 'top' | 'middle' | 'bottom',message:any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 50000,
      position: position
    });

    await toast.present();
  }

  close(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
