import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../Services/Firebase/firebase.service';
import { MainService } from '../Services/Main/main.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  role:any
  email:any
  constructor(
    private firebaseService:FirebaseService,
    private mainService:MainService,
    private activeRoute:ActivatedRoute,
    private router:Router
  ) {

    // console.log("loading....")
    this.activeRoute.data.subscribe((data:any)=>{

      if(data !== null){
        this.role = data.roleResolve.data()["paid"]
        this.email = data.roleResolve.data()["email"]
        this.mainService.globalUserEmail = this.email


        console.log("Email:: ",this.email)
        
        if(this.role === true){
          this.router.navigate(["/"])
        }else if(this.role === false){
          this.router.navigate(["pay",this.email])
        }
        
      }else{

        this.router.navigate(["/login"])
      }
        
    })


  }


  ngOnInit() {}

}
