import { Injectable } from '@angular/core';
import { Song, createSong } from '../Audio/Models/song.model';
import { SongStoreService } from '../Akita/song-store.service';
import { AudioService } from '../Audio/audio.service';
import { StreamState } from '../Audio/Models/stream.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  globalImage:any
  hide=false
  globalUserEmail:any
  playSongName:any
  allLyrics:any
  showLyrics = false
  showMusic = false

  showPlayer = false
  storyName:any
  storyVolume:any
  storyCoverImage:any
  storyAuthor:any
  openSideBar = false
  albumArray:any[] = []

  playlist:Song[] = []

  playing = false

  state: StreamState

  previewType:any
  globalStoryProfileUrl:any

  currentStoryIndex:any
  currentStoriesSize:any


  constructor(
    private songService: SongStoreService,
    private httpsClient: HttpClient,
    private audioService: AudioService
  ) {
    // listen to stream state
    this.audioService.getState()
    .subscribe(state => {
      this.state = state;
    });
   }

  decryptText(name:any):any{
    return "decrypted";
  }

  getPaymentUrl(
    businessName:any,
    currency:any,
    phone:any,
    email:any
    ){

      // console.log(businessName);
      // console.log(currency);
      // console.log(phone);
      // console.log(email);

      

    const url = 'https://sea-turtle-app-uiyuh.ondigitalocean.app/pesapal/veronicasimulizi';

    return this.httpsClient.post(url, { 
      businessName,
      currency,
      amount:2.0,
      phone,
      email,
      merchant:"0744692507"
     });
  }

  setPlaylist(album:any,imageCover:any){
    this.playlist.length = 0
    // console.log(album)
    album.forEach((ele:any)=>{
      
      this.playlist.push(createSong({
          storyName:ele.payload.doc.data()['genre'],
          storyTitle:ele.payload.doc.data()['title'],
          storyCoverImg:imageCover,
          storyAuthor: ele.payload.doc.data()['by'],
          storyDuration: ele.payload.doc.data()['duration'],
          storyUrl:ele.payload.doc.data()['songUrl']
      }))
    })

    //Add to song Store
    this.songService.addSongs(this.playlist)

    // console.log(this.playlist)
  }
}
